/**/

export default {
    district_options: [
        "Shrawasti",
        "Shamli",
        "Firozabad",
        "Farrukhabad",
        "Bijnor",
        "Saharanpur",
        "Sambhal",
        "Pilibhit",
        "Mainpuri",
        "Sitapur",
        "Agra",
        "Banda",
        "Gautam buddha nagar",
        "Basti",
        "Jaunpur",
        "Kanpur dehat",
        "Hathras",
        "Chandauli",
        "Kannauj",
        "Rampur",
        "Siddharthnagar",
        "Amethi",
        "Deoria",
        "Hapur",
        "Mirzapur",
        "Gonda",
        "Hamirpur",
        "Meerut",
        "Bareilly",
        "Kasganj",
        "Jhansi",
        "Ballia",
        "Maharajganj",
        "Bagpat",
        "Unnao",
        "Azamgarh",
        "Mahoba",
        "Hardoi",
        "Ghaziabad",
        "Muzaffarnagar",
        "Lalitpur",
        "Bulandshahr",
        "Sant kabir nagar",
        "Allahabad",
        "Moradabad",
        "Lakhimpur kheri",
        "Balrampur",
        "Sultanpur",
        "Chitrakoot",
        "Etah",
        "Faizabad",
        "Shahjahanpur",
        "Baharaich",
        "Aligarh",
        "Badaun",
        "Fatehpur",
        "Kaushambi",
        "Amroha",
        "Pratapgarh",
        "Raebareli",
        "Etawah",
        "Sonbhadra",
        "Barabanki",
        "Mathura",
        "Gorakhpur",
        "Mau",
        "Ambedkar nagar",
        "Kanpur nagar",
        "Jalaun",
        "Lucknow",
        "Ghazipur",
        "Varanasi",
        "Sant ravidas nagar / bhadoi",
        "Auraiya",
        "Kushinagar"
    ],
    "districts": {
        "Shrawasti": {
            "assembly_options": [
                "Bhinga",
                "Shrawasti"
            ]
        },
        "Shamli": {
            "assembly_options": [
                "Kairana",
                "Thana bhawan",
                "Shamli"
            ]
        },
        "Firozabad": {
            "assembly_options": [
                "Tundla",
                "Jasrana",
                "Firozabad",
                "Shikohabad",
                "Sirsaganj"
            ]
        },
        "Farrukhabad": {
            "assembly_options": [
                "Kaimganj",
                "Amritpur",
                "Farrukhabad",
                "Bhojpur"
            ]
        },
        "Bijnor": {
            "assembly_options": [
                "Najibabad",
                "Nagina",
                "Barhapur",
                "Dhampur",
                "Nehtaur",
                "Bijnor",
                "Chandpur",
                "Noorpur"
            ]
        },
        "Saharanpur": {
            "assembly_options": [
                "Behat",
                "Nakur",
                "Saharanpur nagar",
                "Saharanpur",
                "Deoband",
                "Rampur maniharan",
                "Gangoh"
            ]
        },
        "Sambhal": {
            "assembly_options": [
                "Chandausi",
                "Asmoli",
                "Sambhal",
                "Gunnaur"
            ]
        },
        "Pilibhit": {
            "assembly_options": [
                "Pilibhit",
                "Barkhera",
                "Puranpur",
                "Bisalpur"
            ]
        },
        "Mainpuri": {
            "assembly_options": [
                "Mainpuri",
                "Bhongaon",
                "Kishni",
                "Karhal"
            ]
        },
        "Sitapur": {
            "assembly_options": [
                "Maholi",
                "Sitapur",
                "Hargaon",
                "Laharpur",
                "Biswan",
                "Sevata",
                "Mohmoodabad",
                "Sidhauli",
                "Misrikh"
            ]
        },
        "Agra": {
            "assembly_options": [
                "Etmadpur",
                "Agra cantt.",
                "Agra south",
                "Agra north",
                "Agra rural",
                "Fatehpur sikri",
                "Kheragarh",
                "Fatehabad",
                "Bah"
            ]
        },
        "Banda": {
            "assembly_options": [
                "Tindwari",
                "Baberu",
                "Naraini",
                "Banda"
            ]
        },
        "Gautam buddha nagar": {
            "assembly_options": [
                "Noida",
                "Dadri",
                "Jewar"
            ]
        },
        "Basti": {
            "assembly_options": [
                "Harraiya",
                "Kaptanganj",
                "Rudhauli",
                "Basti sadar",
                "Mahadewa"
            ]
        },
        "Jaunpur": {
            "assembly_options": [
                "Badlapur",
                "Shahganj",
                "Jaunpur",
                "Malhani",
                "Mungra badshahpur",
                "Machhlishahr",
                "Mariyahu",
                "Zafrabad",
                "Kerakat"
            ]
        },
        "Kanpur dehat": {
            "assembly_options": [
                "Rasulabad",
                "Akbarpur-raniya",
                "Sikandra",
                "Bhognipur"
            ]
        },
        "Hathras": {
            "assembly_options": [
                "Hathras",
                "Sadabad",
                "Sikandra rao"
            ]
        },
        "Chandauli": {
            "assembly_options": [
                "Mughalsarai",
                "Sakaldiha",
                "Saiyadraja",
                "Chakia"
            ]
        },
        "Kannauj": {
            "assembly_options": [
                "Chhibramau",
                "Tirwa",
                "Kannauj"
            ]
        },
        "Rampur": {
            "assembly_options": [
                "Suar",
                "Chamraua",
                "Bilaspur",
                "Rampur",
                "Milak"
            ]
        },
        "Siddharthnagar": {
            "assembly_options": [
                "Shohratgarh",
                "Kapilvastu",
                "Bansi",
                "Itwa",
                "Domariyaganj"
            ]
        },
        "Amethi": {
            "assembly_options": [
                "Tiloi",
                "Jagdishpur",
                "Gauriganj",
                "Amethi"
            ]
        },
        "Deoria": {
            "assembly_options": [
                "Rudrapur",
                "Deoria",
                "Pathardeva",
                "Rampur karkhana",
                "Bhatpar rani",
                "Salempur",
                "Barhaj"
            ]
        },
        "Hapur": {
            "assembly_options": [
                "Dholana",
                "Hapur",
                "Garhmukteshwar"
            ]
        },
        "Mirzapur": {
            "assembly_options": [
                "Chhanbey",
                "Mirzapur",
                "Majhawan",
                "Chunar",
                "Marihan"
            ]
        },
        "Gonda": {
            "assembly_options": [
                "Mehnaun",
                "Gonda",
                "Katra bazar",
                "Colonelganj",
                "Tarabganj",
                "Mankapur",
                "Gaura"
            ]
        },
        "Hamirpur": {
            "assembly_options": [
                "Hamirpur",
                "Rath"
            ]
        },
        "Meerut": {
            "assembly_options": [
                "Siwalkhas",
                "Sardhana",
                "Hastinapur",
                "Kithore",
                "Meerut cantt.",
                "Meerut",
                "Meerut south"
            ]
        },
        "Bareilly": {
            "assembly_options": [
                "Baheri",
                "Meerganj",
                "Bhojipura",
                "Nawabganj",
                "Faridpur",
                "Bithari chainpur",
                "Bareilly",
                "Bareilly cantt.",
                "Aonla"
            ]
        },
        "Kasganj": {
            "assembly_options": [
                "Kasganj",
                "Amanpur",
                "Patiyali"
            ]
        },
        "Jhansi": {
            "assembly_options": [
                "Babina",
                "Jhansi nagar",
                "Mauranipur",
                "Garautha"
            ]
        },
        "Ballia": {
            "assembly_options": [
                "Belthara road",
                "Rasara",
                "Sikanderpur",
                "Phephana",
                "Ballia nagar",
                "Bansdih",
                "Bairia"
            ]
        },
        "Maharajganj": {
            "assembly_options": [
                "Paniyara",
                "Pharenda",
                "Nautanwa",
                "Siswa",
                "Maharajganj"
            ]
        },
        "Bagpat": {
            "assembly_options": [
                "Chhaprauli",
                "Baraut",
                "Baghpat"
            ]
        },
        "Unnao": {
            "assembly_options": [
                "Bangermau",
                "Safipur",
                "Mohan",
                "Unnao",
                "Bhagwantnagar",
                "Purwa"
            ]
        },
        "Azamgarh": {
            "assembly_options": [
                "Atrauliya",
                "Gopalpur",
                "Sagri",
                "Mubarakpur",
                "Azamgarh",
                "Nizamabad",
                "Phoolpur pawai",
                "Didarganj",
                "Lalganj",
                "Mehnagar"
            ]
        },
        "Mahoba": {
            "assembly_options": [
                "Mahoba",
                "Charkhari"
            ]
        },
        "Hardoi": {
            "assembly_options": [
                "Sawayazpur",
                "Shahabad",
                "Hardoi",
                "Gopamau",
                "Sandi",
                "Bilgram mallanwan",
                "Balamau",
                "Sandila"
            ]
        },
        "Ghaziabad": {
            "assembly_options": [
                "Loni",
                "Muradnagar",
                "Sahibabad",
                "Ghaziabad",
                "Modi nagar"
            ]
        },
        "Muzaffarnagar": {
            "assembly_options": [
                "Budhana",
                "Charthawal",
                "Purqazi",
                "Muzaffarnagar",
                "Khatauli",
                "Meerapur"
            ]
        },
        "Lalitpur": {
            "assembly_options": [
                "Lalitpur",
                "Mehroni"
            ]
        },
        "Bulandshahr": {
            "assembly_options": [
                "Sikandrabad",
                "Bulandshahr",
                "Syana",
                "Anupshahr",
                "Debai",
                "Shikarpur",
                "Khurja"
            ]
        },
        "Sant kabir nagar": {
            "assembly_options": [
                "Menhdawal",
                "Khalilabad",
                "Dhanghata"
            ]
        },
        "Allahabad": {
            "assembly_options": [
                "Phaphamau",
                "Soraon",
                "Phulpur",
                "Pratappur",
                "Handia",
                "Meja",
                "Karachhana",
                "Allahabad west",
                "Allahabad north",
                "Allahabad south",
                "Bara",
                "Koraon"
            ]
        },
        "Moradabad": {
            "assembly_options": [
                "Kanth",
                "Thakurdwara",
                "Moradabad rural",
                "Moradabad nagar",
                "Kundarki",
                "Bilari"
            ]
        },
        "Lakhimpur kheri": {
            "assembly_options": [
                "Palia",
                "Nighasan",
                "Gola gokrannath",
                "Sri nagar",
                "Dhaurahra",
                "Lakhimpur kheri",
                "Kasta",
                "Mohammdi"
            ]
        },
        "Balrampur": {
            "assembly_options": [
                "Tulsipur",
                "Gainsari",
                "Utraula",
                "Balrampur"
            ]
        },
        "Sultanpur": {
            "assembly_options": [
                "Isauli",
                "Sultanpur",
                "Sadar",
                "Lambhua",
                "Kadipur"
            ]
        },
        "Chitrakoot": {
            "assembly_options": [
                "Chitrakoot",
                "Manikpur"
            ]
        },
        "Etah": {
            "assembly_options": [
                "Aliganj",
                "Etah",
                "Marhara",
                "Jalesar"
            ]
        },
        "Faizabad": {
            "assembly_options": [
                "Rudauli",
                "Milkipur",
                "Bikapur",
                "Ayodhya",
                "Goshainganj"
            ]
        },
        "Shahjahanpur": {
            "assembly_options": [
                "Katra",
                "Jalalabad",
                "Tilhar",
                "Powayan",
                "Shahjahanpur",
                "Dadraul"
            ]
        },
        "Baharaich": {
            "assembly_options": [
                "Balha",
                "Nanpara",
                "Matera",
                "Mahasi",
                "Baharaich",
                "Payagpur",
                "Kaiserganj"
            ]
        },
        "Aligarh": {
            "assembly_options": [
                "Khair",
                "Barauli",
                "Atrauli",
                "Chharra",
                "Koil",
                "Aligarh",
                "Iglas"
            ]
        },
        "Badaun": {
            "assembly_options": [
                "Bisauli",
                "Sahaswan",
                "Bilsi",
                "Badaun",
                "Shekhupur",
                "Dataganj"
            ]
        },
        "Fatehpur": {
            "assembly_options": [
                "Jahanabad",
                "Bindki",
                "Fatehpur",
                "Ayah shah",
                "Husainganj",
                "Khaga"
            ]
        },
        "Kaushambi": {
            "assembly_options": [
                "Sirathu",
                "Manjhanpur",
                "Chail"
            ]
        },
        "Amroha": {
            "assembly_options": [
                "Dhanaura",
                "Naugawan sadat",
                "Amroha",
                "Hasanpur"
            ]
        },
        "Pratapgarh": {
            "assembly_options": [
                "Rampur khas",
                "Babaganj",
                "Kunda",
                "Vishwanath ganj",
                "Pratapgarh",
                "Patti",
                "Raniganj"
            ]
        },
        "Raebareli": {
            "assembly_options": [
                "Bachhrawan",
                "Harchandpur",
                "Rae bareli",
                "Salon",
                "Sareni",
                "Unchahar"
            ]
        },
        "Etawah": {
            "assembly_options": [
                "Jaswantnagar",
                "Etawah",
                "Bharthana"
            ]
        },
        "Sonbhadra": {
            "assembly_options": [
                "Ghorawal",
                "Robertsganj",
                "Obra",
                "Duddhi"
            ]
        },
        "Barabanki": {
            "assembly_options": [
                "Kursi",
                "Ram nagar",
                "Barabanki",
                "Zaidpur",
                "Dariyabad",
                "Haidergarh"
            ]
        },
        "Mathura": {
            "assembly_options": [
                "Chhata",
                "Mant",
                "Goverdhan",
                "Mathura",
                "Baldev"
            ]
        },
        "Gorakhpur": {
            "assembly_options": [
                "Caimpiyarganj",
                "Pipraich",
                "Gorakhpur urban",
                "Gorakhpur rural",
                "Sahajanwa",
                "Khajani",
                "Chauri-chaura",
                "Bansgaon",
                "Chillupar"
            ]
        },
        "Mau": {
            "assembly_options": [
                "Madhuban",
                "Ghosi",
                "Muhammadabad-gohna",
                "Mau"
            ]
        },
        "Ambedkar nagar": {
            "assembly_options": [
                "Katehari",
                "Tanda",
                "Alapur",
                "Jalalpur",
                "Akbarpur"
            ]
        },
        "Kanpur nagar": {
            "assembly_options": [
                "Bilhaur",
                "Bithoor",
                "Kalyanpur",
                "Govindnagar",
                "Sishamau",
                "Arya nagar",
                "Kidwai nagar",
                "Kanpur cantt.",
                "Maharajpur",
                "Ghatampur"
            ]
        },
        "Jalaun": {
            "assembly_options": [
                "Madhaugarh",
                "Kalpi",
                "Orai"
            ]
        },
        "Lucknow": {
            "assembly_options": [
                "Malihabad",
                "Bakshi kaa talab",
                "Sarojini nagar",
                "Lucknow west",
                "Lucknow north",
                "Lucknow east",
                "Lucknow central",
                "Lucknow cantt.",
                "Mohanlalganj"
            ]
        },
        "Ghazipur": {
            "assembly_options": [
                "Jakhanian",
                "Saidpur",
                "Ghazipur",
                "Jangipur",
                "Zahoorabad",
                "Mohammadabad",
                "Zamania"
            ]
        },
        "Varanasi": {
            "assembly_options": [
                "Pindra",
                "Ajagara",
                "Shivpur",
                "Rohaniya",
                "Varanasi north",
                "Varanasi south",
                "Varanasi cantt.",
                "Sevapuri"
            ]
        },
        "Sant ravidas nagar / bhadoi": {
            "assembly_options": [
                "Bhadohi",
                "Gyanpur",
                "Aurai"
            ]
        },
        "Auraiya": {
            "assembly_options": [
                "Bidhuna",
                "Dibiyapur",
                "Auraiya"
            ]
        },
        "Kushinagar": {
            "assembly_options": [
                "Khadda",
                "Padrauna",
                "Tamkuhi raj",
                "Fazilnagar",
                "Kushinagar",
                "Hata",
                "Ramkola"
            ]
        }
    }
}
