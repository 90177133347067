import {Button, Container, Form} from "semantic-ui-react";
import axios from 'axios'
import './App.css';
import 'semantic-ui-css/semantic.min.css'
import {useEffect, useState} from "react";

import formattedOptions from "./options";
const districtOptions = formattedOptions.district_options.sort((a, b) => a.toLowerCase() > b.toLowerCase())

/**
 *
 * @param assembly
 * @param district
 * @param name
 * @param phone
 * @param registration_code
 * @returns {Promise<AxiosResponse<any>>}
 */
const makeSubmission = ({
                            assembly,
                            district,
                            name,
                            phone,
                            registration_code,
                        }) => axios.post('https:///ration.blame.cc/api/electricity-campaign/', [{assembly, district, name, phone, registration_code}])

function App() {
    const [formDetails, setFormDetails] = useState({
        district: null,
        assembly: null,
        phone: null,
        name: null,
        registration_code: null,
    })

    const handleChange = (fieldName, value) => {
        if (!value) {
            // eslint-disable-next-line no-param-reassign
            value = null
        }
        setFormDetails({
            ...formDetails,
            [fieldName]: value,
        })
    }

    const {
        district,
        assembly,
        phone,
        name,
        registration_code,
    } = formDetails

    const [assemblyOptions, setAssemblyOptions] = useState([])

    const phoneValid = phone ? phone.length === 10 : false
    const allFieldsValid = phoneValid && name && assembly && (registration_code && registration_code.length === 6)

    const [assemblyOpen, setAssemblyOpen] = useState(false);
    const [assemblyValue, setAssemblyValue] = useState(null);

    // try to set panchayat options
    useEffect(() => {
        const processOptions = (district ? formattedOptions.districts[district] && formattedOptions.districts[district].assembly_options : []).map((item) => ({'text':item, 'value':item}))
        setAssemblyOptions(processOptions)
    }, [district])

    useEffect(() => {
        setFormDetails({...formDetails, assembly: assemblyValue})
    }, [assemblyValue])

    console.log({        district,
        assembly,
        phone,
        name,
        registration_code,})

    console.log(phoneValid,name,assembly,(registration_code && registration_code.length === 6))

    return (
        <Container>
            <Form>
                <Form.Dropdown onChange={(e, { value }) => {
                    setAssemblyValue(null)
                    setFormDetails(
                        {
                            ...formDetails,
                            district: value
                        })
                }} fluid options={districtOptions.map(item => ({'text': item, 'value': item}))} label={'District'} />
                <Form.Dropdown  onChange={(e, { value }) => (setAssemblyValue(value))} fluid options={assemblyOptions} label={'Assembly'} />
                <Form.Input value={name} onChange={(e, { value }) => handleChange('name', value)} label={'नाम'} placeholder='नाम' />

                <Form.Input value={phone} minLength={10} maxLength={10} type={'tel'} onChange={(e, { value }) => handleChange('phone', value)} label={'फ़ोन नंबर'} placeholder='फ़ोन नंबर' />

                <Form.Input value={registration_code} onChange={(e, { value }) => handleChange('registration_code', value)} label={'रजिस्ट्रेशन कोड'} placeholder='रजिस्ट्रेशन कोड' />

                <Button disabled={!allFieldsValid} fluid onClick={() => {
                    makeSubmission({
                        assembly,
                        district,
                        name,
                        phone,
                        registration_code,
                    }).then(() => {
                        setFormDetails({
                            ...formDetails,
                            name: '',
                            phone: '',
                            registration_code: '',
                        })
                    }).catch(() => alert('error in submitting, please re-check details'))
                }} type='submit'>Submit</Button>
            </Form>
        </Container>
    );
}

export default App;
